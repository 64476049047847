import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Img from 'gatsby-image'

const Template = ({data, pageContext}) => {

    const { next, prev } = pageContext
    const { markdownRemark }  = data
    const title = markdownRemark.frontmatter.title
    const html = markdownRemark.html
    const featuredImage = markdownRemark.frontmatter.featuredImage.childImageSharp.fluid
    return (
        <Layout>
            <div class="bg-white p-8 border-2 border-red-700 sm:max-w-xl md:max-w-xl lg:max-w-5xl">
                <h2 className="font-bold text-4xl pb-4">{title}</h2>
                <Img className="z-10" fluid={featuredImage} />
                <div dangerouslySetInnerHTML={{__html: html}}></div>
                <div className="mt-12 font-bold text-red-700">
                {next && <Link to={next.frontmatter.path}>
                    Next Post
                    </Link>}
                {prev && <Link to={prev.frontmatter.path}>
                    Prev Post
                    </Link>}
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query($pathSlug: String!) {
        markdownRemark(frontmatter: { path: {eq: $pathSlug} }) {
            html
            frontmatter {
                title
                featuredImage { 
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`

export default Template
